import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-left" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "text-left" }
const _hoisted_5 = {
  key: 0,
  class: "text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "subtitle-1 text-uppercase" }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createTextVNode(" gearList ")),
                  (_ctx.store.isAdmin())
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        to: "/magazyn/addNewGear"
                      }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode(" Dodaj nowy sprzęt ")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_data_table, {
                headers: _ctx.store.isAdmin() ? _ctx.adminHeaders : _ctx.userHeaders,
                items: _ctx.gearList
              }, {
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_1, _toDisplayString(item.itemId), 1),
                    _createElementVNode("td", _hoisted_2, _toDisplayString(item.name), 1),
                    _createElementVNode("td", {
                      class: "text-left",
                      style: _normalizeStyle({ color: item.status === 0 ? 'red' : item.status === 2 ? 'orange' : 'black' })
                    }, _toDisplayString(item.statusName), 5),
                    _createElementVNode("td", _hoisted_3, _toDisplayString(item.typeName), 1),
                    _createElementVNode("td", _hoisted_4, _toDisplayString(item.details), 1),
                    (_ctx.store.userData?.type === 1)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                          _createVNode(_component_v_btn, {
                            to: `/magazyn/editGear/${item.id}`
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Edytuj " + _toDisplayString(item.key), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}