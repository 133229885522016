<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Rezarwacje ({{showAllReservation ? 'wszystkie' : 'ostatnie 10'}})

            <v-btn @click="getAllReservations"> Pobierz wszystkie rezerwacje</v-btn>

            <v-btn @click="search = true" v-if="store.isAdmin()"> Wyszukaj</v-btn>

            <v-select
                    v-if="store.isAdmin() && search"
                    v-model="findReservationWithItem"
                    :items="gearList"
                    item-value="id"
                    :item-title="(item) => item.name + ' [' + item.itemId + ']'"
                    label="Wyszukaj rezerwacje zawierające wybrany sprzęt"
                    variant="underlined"
                    clearable
            ></v-select>

        </v-card-title>



        <v-data-table :headers="headers" :items="rentList">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.eventName }}</td>
              <td>{{ item.location }}</td>
              <td>{{ formattedDate(item.startDate) }}</td>
              <td>{{ formattedDate(item.endDate) }}</td>
              <td>{{ formattedDate(item.createdAt, true) }}</td>
              <td>{{ item.userName }}</td>
              <td>{{ item.name }}</td>
              <td>
                <v-btn :to="`/magazyn/rentDetails/${item.id}`"> Zobacz</v-btn>
                  <v-btn v-if="store.isAdmin() && (item.status === 3 || item.status === 4)" @click="removeRent(item.id)"> Usuń</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { format } from "date-fns";
import { store } from "@/store/store";
import { url } from "@/consts/consts";
export default defineComponent({
  name: "rentList",
    computed: {
        store() {
            return store
        }
    },
  setup() {
    const headers = [
      { title: "Nazwa eventu", key: "eventName" },
      { title: "Lokalizacja", key: "location" },
      { title: "Start wynajmu", key: "eventStartDate" },
      { title: "Koniec wynajmu", key: "eventEndDate" },
      { title: "Data zapytania", key: "createdAt" },
      { title: "Kto", key: "userId" },
      { title: "Status", key: "status" },
      { title: "Akcja", key: "action" },
    ];


    const rentList = ref([]);
      const gearList = ref([]);
      const findReservationWithItem = ref(null)
    const showAllReservation = ref(false)
      const all = ref(false);
      const search = ref(false);


    function formattedDate(originalDateString: string, time = false) {
      const originalDate = new Date(originalDateString);
      return time
        ? format(originalDate, "dd.MM.yyyy HH:mm")
        : format(originalDate, "dd.MM.yyyy");
    }

    async function getAllReservations() {
        all.value = true
        getReservation()
    }

    async function removeRent(id: number) {
        try {
            const response = await fetch(`${url}/api/reservation/${id}?userId=${store.userData.id}`, {
                method: 'DELETE',
            })
            getReservation()
        } catch (error: any) {
            console.error("Error delete reservation:", error.message);
        }
    }

    async function getReservation() {
        let apiEndpoint = `${url}/api/reservation?userId=${store.userData.id}&userType=${store.userData.type}&all=${all.value}`
        if(findReservationWithItem.value) {
            apiEndpoint += `&gearId=${findReservationWithItem.value}`
        }

        try {
            const response = await fetch(apiEndpoint);
            rentList.value = await response.json();
        } catch (error: any) {
            console.error("Error loading gear status:", error.message);
        }
    }

      async function getGear() {
          try {
              const response = await fetch(`${url}/api/gear`);
              gearList.value = await response.json();
          } catch (error: any) {
              console.error("Error loading gear status:", error.message);
          }
      }

    onMounted(async () => {
        getReservation()
        getGear()
    });

      watch(findReservationWithItem, async (newGear) => {
          getReservation()
      });

    return { headers, rentList, gearList, formattedDate, getAllReservations, showAllReservation, findReservationWithItem, removeRent, search };
  },
});
</script>
