export const url = 'https://nowakproduction7.usermd.net:3005';  // PRODUCTION
// export const url = 'http://localhost:3005'; // LOCAL
export const itemsList = [
  { id: 1, itemId: 1111, name: "Kabel SDI 50m", status: 1, details: "" },
  { id: 2, itemId: 2345, name: "Kabel SDI 20m", status: 1, details: "" },
  { id: 3, itemId: 4123, name: "Kabel SDI 10m", status: 1, details: "" },
  { id: 4, itemId: 2123, name: "Lumix FZ2000", status: 1, details: "" },
];

export const itemStatus = [
  { id: 0, name: "Uszkodzony", description: "" },
  { id: 1, name: "Sprawny", description: "" },
  { id: 2, name: "Do sprawdzenia", description: "" },
];

export const waitTo = [
  { id: 0, name: "Oczekuje na akceptacje admina" },
  { id: 1, name: "Oczekuje na akceptacje klienta" },
  { id: 2, name: "Zaakceptowane" },
];

export const reservationList = [
  {
    id: 1,
    status: 0,
    userID: 1,
    eventName: "Nazwa imprezy",
    location: "Miejsce gdzie sprzęt będzie pracował",
    startDate: "", // "2024-03-09"
    endDate: "",
    eventStartDate: "",
    eventEndDate: "",
    selectedItems: "[1, 2, 3, 4]",
    invitedUsers: "",
    comments: "",
  },
];

export const reservationStatus = [
  { id: 0, name: "Oczekuje na akceptacje", description: "" },
  { id: 1, name: "Zaakceptowany", description: "" },
  { id: 2, name: "Odrzucony", description: "" },
];

export const users = [
  {
    id: 1,
    firstName: "Dawid",
    lastName: "Nowak",
    mail: "dawid.nowak768@gmail.com",
    tel: "790770014",
  },
];
