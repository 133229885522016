import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-left" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "text-left"
}
const _hoisted_4 = { class: "text-left" }
const _hoisted_5 = {
  key: 2,
  class: "text-left"
}
const _hoisted_6 = {
  key: 3,
  class: "text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "rentalForm",
    class: "pa-4"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.eventName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableNewRent.eventName) = $event)),
                label: "Nazwa eventu",
                variant: "underlined",
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "readonly", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.location,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mutableNewRent.location) = $event)),
                label: "Miejsce gdzie sprzęt będzie pracował",
                variant: "underlined",
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "readonly", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.startDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mutableNewRent.startDate) = $event)),
                type: "date",
                label: "Odbiór sprzętu",
                variant: "underlined",
                onChange: _ctx.getReservedGearOnThatDate,
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "onChange", "readonly", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.endDate,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mutableNewRent.endDate) = $event)),
                type: "date",
                label: "Oddanie sprzętu",
                variant: "underlined",
                onChange: _ctx.getReservedGearOnThatDate,
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "onChange", "readonly", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_v_autocomplete, {
        modelValue: _ctx.mutableNewRent.selectedItems,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.mutableNewRent.selectedItems) = $event)),
        items: [..._ctx.items, ..._ctx.multiItems],
        "item-value": "id",
        "item-title": (item) => item.name + ' [' + item.itemId + ']',
        label: "Potrzebny sprzęt do wynajęcia",
        multiple: "",
        variant: "underlined",
        rules: [v => !!v || 'To pole jest wymagane']
      }, null, 8, ["modelValue", "items", "item-title", "rules"]), [
        [_vShow, !_ctx.readOnly()]
      ]),
      (_ctx.addNew)
        ? _withDirectives((_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.multipleItems.quantity,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.multipleItems.quantity) = $event)),
                    label: "Potrzebna ilość",
                    type: "number",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_v_autocomplete, {
                    modelValue: _ctx.multipleItems.type,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.multipleItems.type) = $event)),
                    items: _ctx.gearType,
                    "item-value": "id",
                    "item-title": "name",
                    label: "Potrzebny typ sprzętu do wynajęcia",
                    variant: "underlined"
                  }, null, 8, ["modelValue", "items"]), [
                    [_vShow, !_ctx.readOnly()]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { onClick: _ctx.addMultipleItems }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" Dodaj ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)), [
            [_vShow, _ctx.mutableNewRent.status === 1]
          ])
        : _createCommentVNode("", true),
      true
        ? _withDirectives((_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: 
            _ctx.anyItemHaveLockState() && _ctx.store.isAdmin() ? _ctx.headersForAdmin : _ctx.headersForUser
          ,
                    items: _ctx.gearItemsWithState()
                  }, {
                    item: _withCtx(({ item }) => [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_1, [
                          (_ctx.store.isAdmin() && _ctx.mutableNewRent.status === 3)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                to: `/magazyn/editGear/${item.id}`
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.gearName + " [" + item.itemId + "]"), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.gearName + " [" + item.itemId + "]"), 1))
                        ]),
                        (_ctx.anyItemHaveLockState() && _ctx.store.isAdmin())
                          ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(item.message), 1))
                          : _createCommentVNode("", true),
                        (!_ctx.store.isAdmin())
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 1,
                              class: "text-left",
                              style: _normalizeStyle({ color: item.status === 2 ? 'red' : item.status === -1 ? 'black' : 'orange' })
                            }, [
                              _createElementVNode("span", null, _toDisplayString(item.status === -1
                        ? "Dostępny"
                        : item.status === 2
                            ? "Zarezerwowany"
                            : "Oczekuje na zatwierdzenie"), 1)
                            ], 4))
                          : _createCommentVNode("", true),
                        _createElementVNode("td", _hoisted_4, _toDisplayString(item.statusName), 1),
                        (_ctx.anyItemHaveLockState() && !((_ctx.mutableNewRent.status == 1 || _ctx.mutableNewRent.status == 2) && !_ctx.store.isAdmin()))
                          ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                              _withDirectives(_createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.removeItem(item.selectedItemId))
                              }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" Usuń z wynajmu")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]), [
                                [_vShow, item.status && (_ctx.store.isAdmin() || _ctx.store.isOwner(_ctx.mutableNewRent.userId))]
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.mutableNewRent.status === 2)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.gearHasProblem[item.id],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.gearHasProblem[item.id]) = $event),
                                label: "Uszkodzony"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "items"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)), [
            [_vShow, _ctx.mutableNewRent.status < 4]
          ])
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.eventStartDate,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.mutableNewRent.eventStartDate) = $event)),
                type: "date",
                label: "Start eventu",
                variant: "underlined",
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "readonly", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewRent.eventEndDate,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.mutableNewRent.eventEndDate) = $event)),
                type: "date",
                label: "Koniec eventu",
                variant: "underlined",
                readonly: _ctx.readOnly(),
                rules: [v => !!v || 'To pole jest wymagane']
              }, null, 8, ["modelValue", "readonly", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.mutableNewRent.invitedUsers,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.mutableNewRent.invitedUsers) = $event)),
        label: "Zaproszone osoby (opcjonalnie)",
        variant: "underlined",
        readonly: _ctx.readOnly()
      }, null, 8, ["modelValue", "readonly"]),
      _createVNode(_component_v_textarea, {
        modelValue: _ctx.mutableNewRent.comments,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.mutableNewRent.comments) = $event)),
        label: "Szczegóły",
        variant: "underlined",
        readonly: _ctx.readOnly() && _ctx.mutableNewRent.status !== 2
      }, null, 8, ["modelValue", "readonly"]),
      _createVNode(_component_v_textarea, {
        modelValue: _ctx.mutableNewRent.technicalComment,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.mutableNewRent.technicalComment) = $event)),
        label: "Szczegóły problemów sprzętowych",
        variant: "underlined",
        readonly: _ctx.readOnly() && _ctx.mutableNewRent.status !== 2,
        rules: [_ctx.validateRequired]
      }, null, 8, ["modelValue", "readonly", "rules"]),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            outlined: "",
            color: "primary",
            to: "/magazyn"
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("Anuluj ")
            ])),
            _: 1
          }),
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1)),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.goToEditMode,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode(" Edytuj ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && !_ctx.isEdit]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitReject,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("Odrzuć (->4) ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && !_ctx.isEdit && (_ctx.mutableNewRent.status !== 3 && _ctx.mutableNewRent.status !== 4)]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.cancelEditMode,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode(" Anuluj Edycje i cofnij zmiany ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && _ctx.isEdit]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitEditedAsClient,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("Zatwierdź zmienione i zaakceptuj (->2) ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && _ctx.isEdit && _ctx.store.isAdmin()]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitEditedAsAdmin,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("Zatwierdź zmienione (->0) ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && _ctx.isEdit && _ctx.store.isAdmin()]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitNew,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode("Zatwierdź zmienione (->1) ")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && _ctx.isEdit && _ctx.store.isOwner(_ctx.mutableNewRent.userId) && !_ctx.store.isAdmin()]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitAccept,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createTextVNode("Zatwierdź (->2)")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.showButtons() && _ctx.mutableNewRent.status !== 2 && !_ctx.hideAccept && !_ctx.isEdit && (_ctx.mutableNewRent.status !== 3 && _ctx.mutableNewRent.status !== 4)]
          ]),
          _withDirectives(_createVNode(_component_v_btn, {
            onClick: _ctx.submitReturn,
            color: "primary"
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("Zwróć (->3)")
            ])),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.mutableNewRent.status === 2 && (_ctx.store.isAdmin() || _ctx.store.isOwner(_ctx.mutableNewRent.userId)) && !_ctx.isEdit]
          ]),
          (_ctx.addNew)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                onClick: _ctx.submitNew,
                color: "primary"
              }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("Dodaj nowy wynajem (->1)")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}