<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Szczegóły zapytania o wynajem na {{ rentDetails.eventName }}
        </v-card-title>
        <RentForm
          :rent="rentDetails"
          :activeEdit="false"
          @submit="submit"
          @submitEdited="submitEdited"
          @submitReject="submitReject"
        ></RentForm>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import RentForm from "@/components/RentForm.vue";
import { useRoute } from "vue-router";
import { format } from "date-fns";
import router from "@/router";
import { store } from "@/store/store";
import { url } from "@/consts/consts";
export default defineComponent({
  name: "rentDetails",
  components: { RentForm },
  setup() {
    const route: any = useRoute();
    const rentDetails = ref([]);
    const rentId: any = ref(null);

    function formattedDate(originalDateString: string) {
      const originalDate = new Date(originalDateString);
      return format(originalDate, "yyyy-MM-dd");
    }

    onMounted(async () => {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            return;
        }
      rentId.value = parseInt(route.params.id);
      try {
        const response = await fetch(
          `${url}/api/reservation/${rentId.value}?userId=${store.userData.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        );

          if(response.status === 403){
              await router.push({name: 'error403'});
          } else {
              const data = await response.json();

              data[0].startDate = formattedDate(data[0].startDate);
              data[0].endDate = formattedDate(data[0].endDate);
              data[0].eventStartDate = formattedDate(data[0].eventStartDate);
              data[0].eventEndDate = formattedDate(data[0].eventEndDate);
              data[0].selectedItems = JSON.parse(data[0].selectedItems);


              const gearWithConflictsResponse = await fetch(
                  `${url}/api/gear-availability/${rentId.value}?startDate=${data[0].startDate}&endDate=${data[0].endDate}`
              );
              const itemsWithConflictsOnThatDate = await gearWithConflictsResponse.json();

              console.log('itemsWithConflictsOnThatDate',itemsWithConflictsOnThatDate)

              data[0].selectedItemsForThatDate = itemsWithConflictsOnThatDate;

              rentDetails.value = data[0];

          }
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }
    });
    async function submit(value: any): Promise<void> {
        const response = await fetch(
        `${url}/api/reservation/edit/${parseInt(
          route.params.id
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(value),
        }
      );
        if(response.status === 200){
            await router.push({name: 'rentList'});
        }
    }
    function submitEdited(value: any): void {
      console.log("addNewRentEndpoint", value);
    }

    async function submitReject(value: number): Promise<void> {
      const response = await fetch(
        `${url}/api/reservation/edit/${parseInt(
          route.params.id
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: value }),
        }
      );
    }

    return { rentDetails, submit, submitEdited, submitReject };
  },
});
</script>
