import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "subtitle-1 text-uppercase" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Rezarwacje (" + _toDisplayString(_ctx.showAllReservation ? 'wszystkie' : 'ostatnie 10') + ") ", 1),
                  _createVNode(_component_v_btn, { onClick: _ctx.getAllReservations }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Pobierz wszystkie rezerwacje")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  (_ctx.store.isAdmin())
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search = true))
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Wyszukaj")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.store.isAdmin() && _ctx.search)
                    ? (_openBlock(), _createBlock(_component_v_select, {
                        key: 1,
                        modelValue: _ctx.findReservationWithItem,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.findReservationWithItem) = $event)),
                        items: _ctx.gearList,
                        "item-value": "id",
                        "item-title": (item) => item.name + ' [' + item.itemId + ']',
                        label: "Wyszukaj rezerwacje zawierające wybrany sprzęt",
                        variant: "underlined",
                        clearable: ""
                      }, null, 8, ["modelValue", "items", "item-title"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_data_table, {
                headers: _ctx.headers,
                items: _ctx.rentList
              }, {
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(item.eventName), 1),
                    _createElementVNode("td", null, _toDisplayString(item.location), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.startDate)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.endDate)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.createdAt, true)), 1),
                    _createElementVNode("td", null, _toDisplayString(item.userName), 1),
                    _createElementVNode("td", null, _toDisplayString(item.name), 1),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_btn, {
                        to: `/magazyn/rentDetails/${item.id}`
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Zobacz")
                        ])),
                        _: 2
                      }, 1032, ["to"]),
                      (_ctx.store.isAdmin() && (item.status === 3 || item.status === 4))
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            onClick: ($event: any) => (_ctx.removeRent(item.id))
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" Usuń")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}