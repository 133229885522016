<template>
  <v-form class="pa-4">
      <v-row>
          <v-col cols="12">
    <v-text-field
      v-model="mutableNewGear.name"
      label="Nazwa"
      hide-details
      variant="underlined"
    ></v-text-field>
          </v-col>
          <v-col cols="12">
    <v-text-field
      v-model="mutableNewGear.itemId"
      label="itemID"
      hide-details
      variant="underlined"
    ></v-text-field>
          </v-col>
          <v-col cols="6">
    <v-select
      v-model="mutableNewGear.status"
      :items="gearStatus"
      item-value="id"
      item-title="name"
      label="Stan"
      hide-details
      variant="underlined"
    ></v-select>
          </v-col>
          <v-col cols="6">
      <v-select
              v-model="mutableNewGear.type"
              :items="gearType"
              item-value="id"
              item-title="name"
              label="Typ"
              hide-details
              variant="underlined"
      ></v-select>
          </v-col>
          <v-col cols="12">
    <v-textarea
      v-model="mutableNewGear.details"
      label="Szczegóły"
      variant="underlined"
      hide-details
    ></v-textarea>
          </v-col>
          <v-col cols="12">
      <v-text-field
              v-model="mutableNewGear.containItems"
              label="Zawiera sprzęt"
              variant="underlined"
              hide-details
      ></v-text-field>
          </v-col>
      </v-row>
    <v-card-actions>
      <v-btn outlined color="primary" to="/magazyn">Anuluj </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn @click="submit" color="primary"
        >{{ isEdit ? "Zapisz" : "Dodaj" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import { url } from "@/consts/consts";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";

interface Gear {
  id: null | number;
  itemId: null | number;
  name: string;
  status: number;
  details: string;
  containItems: null | string;
}
export default defineComponent({
  name: "GearForm",
  props: {
    gear: {
      type: Object,
      default: () =>
        ({
          id: null,
          itemId: null,
          name: "",
          status: 1,
            type: 0,
          details: "",
            containItems: ""
        } as Gear),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const gear = toRef(props, "gear");
    const mutableNewGear = reactive({
      id: null,
      itemId: null,
      name: "",
      status: 1,
        type: 0,
      details: "",
        containItems: ""
    });

    const gearStatus = ref([]);
      const gearType = ref([]);

    function submit(): void {
      emit("submit", mutableNewGear);
    }

    onMounted(async () => {
      try {
        const response = await fetch(`${url}/api/gear-status`);
        gearStatus.value = await response.json();
      } catch (error: any) {
        console.error("Errorr loading gear status:", error.message);
      }

        try {
            const response = await fetch(`${url}/api/gear-type`);
            gearType.value = await response.json();
        } catch (error: any) {
            console.error("Errorr loading gear status:", error.message);
        }
    });

    watch(gear, (newGear) => {
      Object.assign(mutableNewGear, newGear);
    });

    return { mutableNewGear, gearStatus, gearType, submit };
  },
});
</script>
