<template>
    <div class="home">
        <v-row>
            <v-col cols="12">
                <v-btn to="/magazyn/login"> przejdź do logowania </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {store} from "@/store/store";
import { onMounted } from "vue";
import { useRoute } from 'vue-router';
import router from "@/router";

export default defineComponent({
    name: "StartView",
    computed: {
        store() {
            return store
        }
    },
    setup() {

        onMounted( () => {
            const route = useRoute();
            const view = route.query.view;
            const id = route.query.id;

            console.log(route.query)
            if (view === 'rentDetails' && id) {
                console.log('StartView if')
                // localStorage.setItem('redirectTo', `/magazyn/rentDetails/${id}`);
                // router.push(`/magazyn/rentDetails/${id}`);
            }
        });

        return {};
    },
});
</script>
