import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, { to: "/magazyn/gearList" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Lista sprzętu ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.store.isAdmin())
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, { to: "/magazyn/addNewGear" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Dodaj nowy sprzęt ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, { to: "/magazyn/newRent" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Wypożycz sprzęt")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, { to: "/magazyn/rentList" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Lista wypożyczeń ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}