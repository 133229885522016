import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { class: "pa-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewGear.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableNewGear.name) = $event)),
                label: "Nazwa",
                "hide-details": "",
                variant: "underlined"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewGear.itemId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mutableNewGear.itemId) = $event)),
                label: "itemID",
                "hide-details": "",
                variant: "underlined"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.mutableNewGear.status,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mutableNewGear.status) = $event)),
                items: _ctx.gearStatus,
                "item-value": "id",
                "item-title": "name",
                label: "Stan",
                "hide-details": "",
                variant: "underlined"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.mutableNewGear.type,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mutableNewGear.type) = $event)),
                items: _ctx.gearType,
                "item-value": "id",
                "item-title": "name",
                label: "Typ",
                "hide-details": "",
                variant: "underlined"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                modelValue: _ctx.mutableNewGear.details,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.mutableNewGear.details) = $event)),
                label: "Szczegóły",
                variant: "underlined",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.mutableNewGear.containItems,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.mutableNewGear.containItems) = $event)),
                label: "Zawiera sprzęt",
                variant: "underlined",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            outlined: "",
            color: "primary",
            to: "/magazyn"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Anuluj ")
            ])),
            _: 1
          }),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1)),
          _createVNode(_component_v_btn, {
            onClick: _ctx.submit,
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEdit ? "Zapisz" : "Dodaj"), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}