<template>
  <v-row>
    <v-col>
      <!--        <img to="/" alt="Vue logo" src="./assets/logo.png" />-->
      <v-btn @click="home"> home </v-btn>
        {{returnEmail()}}
        <v-btn to="/"> Test </v-btn>
        <v-btn to="/magazyn/login" @click="logout"> wyloguj </v-btn>
    </v-col>
  </v-row>
  <v-row class="app-body">
    <v-col>
      <router-view />
    </v-col>
  </v-row>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-body {
}
</style>
<script setup>
import { store } from './store/store.ts'
import { useRoute } from 'vue-router';
import router from "@/router";
import { onMounted } from "vue";
import {url} from "@/consts/consts";


const returnEmail = () => {
    return store.userData?.email
};

const logout = () => {
    localStorage.removeItem("jwtToken");
    store.userData = {}
}

const home = () => {
    const redirectTo = localStorage.getItem('redirectTo');
    if (redirectTo) {
        router.push(redirectTo);
        localStorage.removeItem('redirectTo');
    } else {
        router.push({name: 'HomeView'});
    }
}

onMounted( async() => {
    const route = useRoute();
    const view = route.query.view;
    const id = route.query.id;
    const needLogin = route.query.needLogin;
    const token = localStorage.getItem('jwtToken');

    console.log(route.query, view === 'rentDetails', id,  needLogin === 'false')
    if (view === 'rentDetails' && id && needLogin === 'false') {
        router.push(`/magazyn/rentDetails/${id}`);
    } else if(view === 'rentDetails' && id && needLogin === 'true') {
        localStorage.setItem('redirectTo', `/magazyn/rentDetails/${id}`);
        if(token) {
            const response = await fetch(
                `${url}/api/check-token`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }
            );
            const responseData = await response.json();
            if (response.status === 200) {
                store.userData = responseData.user
                router.push(`/magazyn/rentDetails/${id}`);
                localStorage.removeItem('redirectTo');
            } else if (response.status === 401) {
                router.push({name: 'LoginPage'});
            }
        }
    }
});
</script>
