<template>
  <v-form ref="rentalForm" class="pa-4">
      <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="mutableNewRent.eventName"
              label="Nazwa eventu"
              variant="underlined"
              :readonly="readOnly()"
              :rules="[v => !!v || 'To pole jest wymagane']"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="mutableNewRent.location"
              label="Miejsce gdzie sprzęt będzie pracował"
              variant="underlined"
              :readonly="readOnly()"
              :rules="[v => !!v || 'To pole jest wymagane']"
            ></v-text-field>
          </v-col>
      </v-row>
    <v-row>
      <v-col cols="6"
        ><v-text-field
          v-model="mutableNewRent.startDate"
          type="date"
          label="Odbiór sprzętu"
          variant="underlined"
          @change="getReservedGearOnThatDate"
          :readonly="readOnly()"
          :rules="[v => !!v || 'To pole jest wymagane']"
        ></v-text-field
      ></v-col>
      <v-col cols="6">
        <v-text-field
          v-model="mutableNewRent.endDate"
          type="date"
          label="Oddanie sprzętu"
          variant="underlined"
          @change="getReservedGearOnThatDate"
          :readonly="readOnly()"
          :rules="[v => !!v || 'To pole jest wymagane']"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-autocomplete
      v-model="mutableNewRent.selectedItems"
      :items="[...items, ...multiItems]"
      item-value="id"
      :item-title="(item) => item.name + ' [' + item.itemId + ']'"
      label="Potrzebny sprzęt do wynajęcia"
      multiple
      variant="underlined"
      v-show="!readOnly()"
      :rules="[v => !!v || 'To pole jest wymagane']"
    ></v-autocomplete>
     <v-row v-if="addNew" v-show="mutableNewRent.status === 1">
       <v-col>
              <v-text-field v-model="multipleItems.quantity" label="Potrzebna ilość" type="number" variant="underlined">
              </v-text-field>
          </v-col>
          <v-col>
              <v-autocomplete
                      v-model="multipleItems.type"
                      :items="gearType"
                      item-value="id"
                      item-title="name"
                      label="Potrzebny typ sprzętu do wynajęcia"
                      variant="underlined"
                      v-show="!readOnly()"
              ></v-autocomplete>
          </v-col>
          <v-col>
              <v-btn @click="addMultipleItems">
                  Dodaj
              </v-btn>
          </v-col>
      </v-row>
    <v-row v-show="mutableNewRent.status < 4" v-if="true">
      <v-col>
        <v-data-table
          :headers="
            anyItemHaveLockState() && store.isAdmin() ? headersForAdmin : headersForUser
          "
          :items="gearItemsWithState()"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td  class="text-left">

                  <v-btn v-if="store.isAdmin() && mutableNewRent.status === 3" :to="`/magazyn/editGear/${item.id}`" >
                      {{ item.gearName + " [" + item.itemId + "]" }}
                  </v-btn>
                  <span v-else>{{ item.gearName + " [" + item.itemId + "]" }}</span>
              </td>
              <td v-if="anyItemHaveLockState() && store.isAdmin()" class="text-left">
                {{ item.message }}
              </td>
                <td v-if="!store.isAdmin()" class="text-left" :style="{ color: item.status === 2 ? 'red' : item.status === -1 ? 'black' : 'orange' }">
                <span>
                    {{
                    item.status === -1
                        ? "Dostępny"
                        : item.status === 2
                            ? "Zarezerwowany"
                            : "Oczekuje na zatwierdzenie"
                    }}
                </span>
              </td>
                <td class="text-left">
                    {{ item.statusName }}
                </td>
              <td  class="text-left" v-if="anyItemHaveLockState() && !((mutableNewRent.status == 1 || mutableNewRent.status == 2) && !store.isAdmin())">
                <v-btn
                  v-show="item.status && (store.isAdmin() || store.isOwner(mutableNewRent.userId))"
                  @click="removeItem(item.selectedItemId)"
                >
                  Usuń z wynajmu</v-btn
                >
              </td>
                <td class="text-left" v-if="mutableNewRent.status === 2">
                    <v-checkbox v-model="gearHasProblem[item.id]" label="Uszkodzony"></v-checkbox>
                </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"
        ><v-text-field
          v-model="mutableNewRent.eventStartDate"
          type="date"
          label="Start eventu"
          variant="underlined"
          :readonly="readOnly()"
          :rules="[v => !!v || 'To pole jest wymagane']"
        ></v-text-field
      ></v-col>
      <v-col cols="6">
        <v-text-field
          v-model="mutableNewRent.eventEndDate"
          type="date"
          label="Koniec eventu"
          variant="underlined"
          :readonly="readOnly()"
          :rules="[v => !!v || 'To pole jest wymagane']"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-text-field
      v-model="mutableNewRent.invitedUsers"
      label="Zaproszone osoby (opcjonalnie)"
      variant="underlined"
      :readonly="readOnly()"
    ></v-text-field>
    <v-textarea
      v-model="mutableNewRent.comments"
      label="Szczegóły"
      variant="underlined"
      :readonly="readOnly() && mutableNewRent.status !== 2"
    ></v-textarea>
      <v-textarea
              v-model="mutableNewRent.technicalComment"
              label="Szczegóły problemów sprzętowych"
              variant="underlined"
              :readonly="readOnly() && mutableNewRent.status !== 2"
              :rules="[validateRequired]"
      ></v-textarea>
    <v-card-actions>
      <v-btn outlined color="primary" to="/magazyn">Anuluj </v-btn>
      <div class="flex-grow-1"></div>

        <v-btn
                @click="goToEditMode"
                color="primary"
                v-show="showButtons() && !isEdit"
        >
            Edytuj
        </v-btn>

      <v-btn @click="submitReject" color="primary" v-show="showButtons() && !isEdit && (mutableNewRent.status !== 3 && mutableNewRent.status !== 4)"
        >Odrzuć (->4)
      </v-btn>

        <v-btn @click="cancelEditMode"
                color="primary"
                v-show="showButtons() && isEdit"
        >
            Anuluj Edycje i cofnij zmiany
        </v-btn>

      <v-btn
        @click="submitEditedAsClient"
        color="primary"
        v-show="showButtons() && isEdit && store.isAdmin()"
        >Zatwierdź zmienione i zaakceptuj (->2)
      </v-btn>

        <v-btn
                @click="submitEditedAsAdmin"
                color="primary"
                v-show="showButtons() && isEdit && store.isAdmin()"
        >Zatwierdź zmienione (->0)
        </v-btn>

        <v-btn
                @click="submitNew"
                color="primary"
                v-show="showButtons() && isEdit && store.isOwner(mutableNewRent.userId) && !store.isAdmin()"
        >Zatwierdź zmienione (->1)
        </v-btn>

      <v-btn
        @click="submitAccept"
        color="primary"
        v-show="showButtons() && mutableNewRent.status !== 2 && !hideAccept && !isEdit && (mutableNewRent.status !== 3 && mutableNewRent.status !== 4)"
        >Zatwierdź (->2)</v-btn
      >

      <v-btn
        @click="submitReturn"
        color="primary"
        v-show="mutableNewRent.status === 2 && (store.isAdmin() || store.isOwner(mutableNewRent.userId)) && !isEdit"
        >Zwróć (->3)</v-btn
      >

      <v-btn @click="submitNew" color="primary" v-if="addNew"
        >Dodaj nowy wynajem (->1)</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import {
    computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  watch
} from "vue";
import { url } from "@/consts/consts";
import { format } from "date-fns";
import { store } from "@/store/store";

interface EventDetails {
  id: null | number;
  status: null | number;
  userId:  string | number;
  eventName: string;
  location: string;
  startDate: null | string;
  endDate: null | string;
  eventStartDate: null | string;
  eventEndDate: null | string;
  selectedItems: number[];
    selectedMultiItems: number[];
  invitedUsers: string;
  comments: string;
    technicalComment: string;
  selectedItemsForThatDate: any;
}
export default defineComponent({
  name: "rentForm",
    computed: {
        store() {
            return store
        }
    },
  props: {
    rent: {
      type: Object,
      default: () =>
        ({
          id: null,
          status: null,
          userId: '2',
          eventName: "",
          location: "",
          startDate: "2024-03-09",
          endDate: "2024-03-09",
          eventStartDate: "2024-03-09",
          eventEndDate: "2024-03-09",
          selectedItems: [],
            selectedMultiItems: [],
          invitedUsers: "",
          comments: "",
            technicalComment: "",
          selectedItemsForThatDate: [],
        } as EventDetails),
    },
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const rent = toRef(props, "rent");
    const mutableNewRent = reactive<EventDetails>({
      id: null,
      status: 1,
      userId: store.userData.id,
      eventName: "",
      location: "",
      startDate: "",
      endDate: "",
      eventStartDate: "",
      eventEndDate: "",
      selectedItems: [],
        selectedMultiItems: [],
      invitedUsers: "",
      comments: "",
        technicalComment: "",
      selectedItemsForThatDate: [],
    });
    const isEdit = ref(false);
    const cloneReservationState = ref({})
    const hideAccept = ref(false);
    const rentalForm = ref<any>(null);
    const items = ref([]);
    const multiItems = ref([]);
    const itemsWithConflicts = ref([]);
    const gearType = ref([]);
    const multipleItems = ref({
        quantity: 0,
        type: null,
    })

      const gearHasProblem = ref({})

    const headersForAdmin = [
      { title: "Nazwa Sprzętu", key: "gearName" },
      { title: "Konflikt", key: "data" },
        { title: "Status sprzętu", key: "statusName" },
      { title: "Akcja", key: "status" },
    ];

    const headersForUser = [
      { title: "Nazwa Sprzętu", key: "gearName" },
        { title: "Dostępność", key: "status" },
        { title: "Status sprzętu", key: "statusName" },
      { title: "Akcja", key: "status" },
    ];

      const validateRequired = computed(() => {
          return (v: any) => {
              const hasProblem = Object.values(gearHasProblem.value).includes(true);
              return hasProblem && !v ? 'To pole jest wymagane w przypadku zaznaczonego uszkodzonego sprzętu' : true;
          };
      });

    onMounted(async () => {
      try {
        const response = await fetch(`${url}/api/gear`);
        let itemsArray = await response.json();
          items.value = itemsArray.filter((item: any) => {
              if(item.type === 0) {
                  return item
            }
          })
          multiItems.value = itemsArray.filter((item: any) => {
              if(item.type !== 0) {
                  return item
              }
          })
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }

      try {
          const response = await fetch(`${url}/api/gear-type`);
          const responseGearType = await response.json();
          gearType.value = responseGearType.filter((item: any) => item.id > 0)
      } catch (error: any) {
          console.error("Error loading gear status:", error.message);
      }
    });

      async function getReservedGearOnThatDate() {
          if (mutableNewRent.startDate && mutableNewRent.endDate) {
              try {
                  const gearWithConflictsResponse = await fetch(
                      `${url}/api/reserved-gear/${mutableNewRent.id}?startDate=${mutableNewRent.startDate}&endDate=${mutableNewRent.endDate}`
                  );
                  let a = await gearWithConflictsResponse.json();

                  console.log('getReservedGearOnThatDate', a, mutableNewRent.selectedItemsForThatDate)

                  mutableNewRent.selectedItemsForThatDate = a
                  return a
              } catch {
                  console.error("Error getReservedGearOnThatDate");
              }
          }
        }

        async function checkAvailableItems() {
          const old = mutableNewRent.selectedItemsForThatDate
            const newTest = await getReservedGearOnThatDate()


            if(JSON.stringify(newTest) == JSON.stringify(old)) {
              return false
            } else {
                alert(`Dostępność sprzętu uległa zmianie, sprawdź ponownie listę wybranego sprzętu`);
                return true
            }

        }

    function gearItemsWithState(): any {
        const selectedItems = [...items.value, ...multiItems.value].filter((item: any) => mutableNewRent.selectedItems.includes(item.id))

        return selectedItems.map((item: any) => {
            let hasConflict = mutableNewRent.selectedItemsForThatDate.find((el: any) => el.gear_id === item.id);

            if(!hasConflict){
                return {
                    ...item,
                    status: -1, //ZMIENIĆ nazwę status na inną typu dostępność
                    gearName: item.name,
                    selectedItemId: item.id,
                    message: 'Dostępny',
                    hasProblem: false,
                }
            }
            const dateRange = hasConflict?.startDate ? `${formattedDate(hasConflict.startDate) +
            "-" +
            formattedDate(hasConflict.endDate)}` : ''
            const testText: any =
                `${
                    hasConflict.status === 2
                        ? "Wynajęte na '" +
                        hasConflict.name +
                        "' " +
                        dateRange
                        : "Oczekuje na zawierdzenie na '" + hasConflict.name + "'"
                }`
            console.log(item)
            return {
                ...item,
                status: hasConflict.status, //ZMIENIĆ nazwę status na inną typu dostępność
                gearName: item.name,
                selectedItemId: item.id,
                message: testText,
                hasProblem: false,
            }
        })
    }

    function anyItemHaveLockState() {
      let b = gearItemsWithState().filter((item: any) => item.status < 3);

      return b.length;
    }

    function addMultipleItems() {
        const allItems = [...items.value, ...multiItems.value];
        const selectedItemFromType = mutableNewRent.selectedItems
        const allItemsToSelect = allItems.filter((item: any) => item.type === multipleItems.value.type).filter((item: any) => !selectedItemFromType.some((selectedItem: any) => selectedItem === item.id));

        if(allItemsToSelect.length < multipleItems.value.quantity){
            alert(`Nie udało się dodać potrzebnej ilości, dodano ${allItemsToSelect.length}`);
        }

        const itemsToAdd: number[] = []
        allItemsToSelect.forEach((item: any, index) => {
            if(index+1 <= multipleItems.value.quantity){
                itemsToAdd.push(item.id)
            }
        })

        mutableNewRent.selectedItems = [...mutableNewRent.selectedItems, ...itemsToAdd]
        multipleItems.value.quantity = 0;
        multipleItems.value.type = null
    }

    function formattedDate(originalDateString: string) {
      const originalDate = new Date(originalDateString);
      return format(originalDate, "dd.MM.yyyy");
    }
      async function submitNew(): Promise<void> {
          const result = await rentalForm.value.validate();
          if (!result.valid) {
              return;
          }

          if(await checkAvailableItems()){
              return
          }

          mutableNewRent.status = 1;
          emit("submit", mutableNewRent);

    }
    async function submitAccept(): Promise<void> {
        const result = await rentalForm.value.validate();
          if (!result.valid) {
          return;
      }
        mutableNewRent.status = 2;
        emit("submit", mutableNewRent);
    }
    async function submitEditedAsClient(): Promise<void> {
        const result = await rentalForm.value.validate();
        if (!result.valid) {
            return;
        }
            mutableNewRent.status = 2 //mutableNewRent.status === 0 ? 1 : 0;
            emit("submit", mutableNewRent);
    }
    async function submitEditedAsAdmin(): Promise<void> {
          const result = await rentalForm.value.validate();
          if (!result.valid) {
              return;
          }
          mutableNewRent.status = 0 // mutableNewRent.status === 0 ? 1 : 0;
          emit("submit", mutableNewRent);
    }

    async function submitReturn():Promise<void> {
        const result = await rentalForm.value.validate();
        if (!result.valid) {
            return;
        }

        await setToReview()

        mutableNewRent.status = 3;
        emit("submit", mutableNewRent);

    }
    async function submitReject():Promise<void> {
        const result = await rentalForm.value.validate();
        if (!result.valid) {
            return;
        }
            mutableNewRent.status = 4;
            emit("submit", mutableNewRent);

    }

    function readOnly(): boolean {
      return (!store.isAdmin() && !store.isOwner(mutableNewRent.userId)) || (!props.addNew && !isEdit.value);
    }

     function removeItem(id: any) {
          if(!isEdit.value) {
              goToEditMode()
          }
      let a = mutableNewRent.selectedItems.filter((item) => item !== id * 1);
      hideAccept.value = true;
      mutableNewRent.selectedItems = a;
    }

    async function setToReview() {
          await fetch(`${url}/api/gear/update-status`, {
              method: "PUT",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  gearHasProblem: gearHasProblem.value,
              }),
          });
    }

    function showButtons(): boolean {
      return (mutableNewRent.status === 0 || store.isAdmin()) && !props.addNew && (store.isAdmin() || store.isOwner(mutableNewRent.userId));
    }

    function goToEditMode() {
        isEdit.value = true
        cloneReservationState.value =  JSON.parse(JSON.stringify(mutableNewRent))
    }

      function cancelEditMode() {
          Object.assign(mutableNewRent, cloneReservationState.value);
          isEdit.value = false
      }

      watch(
          () => [...mutableNewRent.selectedItems],
          (newVal, oldVal) => {
              if(newVal.length > oldVal.length) {
                  const addedItems = newVal.filter(item => !oldVal.includes(item));
                  const currentAddedItem = addedItems[0]
                  const currentAddedGear: any = [...items.value, ...multiItems.value].find((item: any) => item?.id === currentAddedItem)
                  if(currentAddedGear && currentAddedGear?.containItems){
                      const itemsToAdd = currentAddedGear.containItems.split(",")
                          .filter((num: any) => num)
                          .map(Number);
                      const itemsToAddWithoutDuplicates: any = itemsToAdd.filter((num: any) => !mutableNewRent.selectedItems.includes(num));
                      mutableNewRent.selectedItems = [...mutableNewRent.selectedItems.filter((item: any) => item !== currentAddedItem), ...itemsToAddWithoutDuplicates]

                  }
              }
          },
          { deep: true }
      );



      watch(rent, (newGear) => {
      Object.assign(mutableNewRent, newGear);
    });

    return {
      mutableNewRent,
      items,
      submitNew,
      submitAccept,
        submitEditedAsClient,
        submitEditedAsAdmin,
      submitReject,
      submitReturn,
      isEdit,
      readOnly,
      showButtons,
      formattedDate,
      gearItemsWithState,
      headersForAdmin,
      removeItem,
      headersForUser,
      anyItemHaveLockState,
      hideAccept,
        getReservedGearOnThatDate,
        multiItems,
        gearType,
        multipleItems,
        addMultipleItems,
        rentalForm,
        gearHasProblem,
        validateRequired,
        goToEditMode,
        cancelEditMode
    };
  },
});
</script>
